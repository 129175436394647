import React, {useState} from 'react';
import {graphql} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LayoutInner from "../../components/layoutInner";
import ContactForm from '../../components/ContactForm'
import * as PATHS from "../../utils/paths";
import {joinPaths} from '../../utils/index'

const styles = theme => ({
    root: {},
    heroText: {
        fontSize: 32,
        lineHeight: "48px",
        fontWeight: 400,
        marginBottom: 16
    },
    subheroText: {
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: "regular",
        marginBottom: 16
    },
});


const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle} hideNewsletterForm={true}>
            <SEO title={"Workshop: Equity-Focused Dataviz"} location={{pathname: PATHS.WORKSHOPS_EQUITY_DATAVIZ}}/>

            <LayoutInner>

                <h1>Equity-Focused Dataviz Workshop</h1>
                <img style={{maxWidth: "100%"}}
                     src={joinPaths([PATHS.CDN, "/images/workshop-equity-viz-head.png"])}/>
                <br/><br/>


                <h3>Workshop: How do we visualize inequality without promoting it? How can we communicate social
                    outcomes in ways that inspire positive change?</h3>

                <p>When data represents groups of people, it’s inescapably interpreted through the audience’s prior
                    knowledge, assumptions, biases and stereotypes about the groups being visualized. This makes
                    visualizing social data unique from other topics. It also means that common visualization techniques
                    that are safe in other domains can be harmful when visualizing data about people from minoritized
                    communities.</p>

                <p><a target="_blank"
                      href={"https://3iap.com/dispersion-disparity-equity-centered-data-visualization-research-project-Wi-58RCVQNSz6ypjoIoqOQ/"}>3iap's
                    recent peer-reviewed study</a>, in collaboration with researchers at UMass Amherst’s HCI-VIS Lab,
                    shows that certain common types of data visualizations, meant to raise awareness of social
                    disparities, may actually backfire and reinforce disparities, by encouraging harmful stereotypes
                    about the people being visualized.</p>

                <p>To raise awareness about our findings and help the data visualization community produce more
                    equitable social-data visualizations, 3iap offers workshops tailored to designers, analysts,
                    journalists, and any other interested data communicators.</p>
                <br/>
                <img style={{maxWidth: "100%"}}
                     src={joinPaths([PATHS.CDN, "/images/workshop-equity-viz-deficit-framed-charts-example.png"])}/>
                <br/><br/>


                <p>These workshops help chart makers:</p>
                <ol>
                    <li>Identify and articulate the hidden biases pervasive in charts and graphs covering social outcome
                        disparities.
                    </li>
                    <li>Develop intuition for the surprising ways that social psychology can influence audiences’
                        perceptions of information.
                    </li>
                    <li>Learn alternative design approaches to effectively present equity-focused dataviz in ways that
                        create awareness and inspire change, while upholding the dignity of the people being visualized.
                    </li>
                </ol>

                <p>The workshops are for any groups interested in designing more equitable data visualizations, but
                    we’re especially interested in speaking with:</p>
                <ul>
                    <li>Advocacy groups using data for social good</li>
                    <li>Data journalists covering any aspects of inequality or systemic racism</li>
                    <li>DEI-focused HR or people analytics analysts</li>
                    <li>Social program evaluation experts</li>
                    <li>Public-health communicators reporting outcome disparities</li>
                    <li>Government agencies or non-government organizations sharing statistics about people based on
                        their race,
                        ethnicity, gender or other memberships in marginalized communities
                    </li>
                </ul>

                <p>If this sounds like your organization, then please get in touch. You can reach us in one of the
                    following ways:</p>

                <ul>
                    <li>Email <a href={"mailto:hi+equityworkshop@3iap.com"}>hi+equityworkshop@3iap.com</a><br/></li>
                    <li>Book a time on <a href={"https://calendly.com/3iap/intro-call"}>3iap's Calendly</a></li>
                    <li>Or use the form below to send a message</li>
                </ul>
                <br/><hr/><br/><br/>
                <ContactForm/>
            </LayoutInner>


        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
